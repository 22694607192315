import * as React from "react";
import { Layout } from "../components/Layout";
import GoogleLogo from "../images/chrome.png";
import FirefoxLogo from "../images/firefox.svg";
import Reading from "../images/undraw_reading.svg";
import { Link } from "gatsby";

// markup
const IndexPage = () => {
	return (
		<Layout>
			<div className="flex items-center justify-center h-screen">
				<div className="flex flex-col">
					<div className="flex flex-col-reverse items-center max-w-6xl px-4 mx-auto mt-6 lg:px-0 lg:flex-row">
						<div className="flex flex-col justify-center flex-auto w-full pb-4 text-center lg:pb-0 lg:text-left lg:w-3/5">
							<h1 className="text-4xl lg:text-5xl">Headcanon</h1>
							<h2 className="mt-8 text-2xl font-light lg:text-3xl">
								Download all your favorite fanfics from AO3 and
								Fanfiction.net. No hussle!
							</h2>
							<p className="mt-6">
								With Headcanon you can easily download fanfics
								straight to your e-book reader without even
								having to leave your browser. Just few clicks
								and you can enjoy the next captivating story,
								reading it comfortably on your reading device!
							</p>
							<div className="mx-auto mt-4 lg:mx-0">
								<div className="flex flex-col space-y-2 sm:space-y-0 sm:space-x-2 sm:flex-row">
									<div>
										<span className="relative">
											<a
												href="https://chrome.google.com/webstore/detail/headcanon/cepickgijpghkhpjgggdhnhaffodnkib"
												type="button"
												className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
											>
												<img
													src={GoogleLogo}
													className="w-6 h-6 mr-2"
												/>{" "}
												Add to Chrome
											</a>
										</span>
									</div>
									<div className="relative">
										<span className="z-40 absolute origin-center transform rotate-12 inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-purple-100 text-purple-800 -right-4 -top-4">
											Soon!
										</span>
										<button
											disabled
											type="button"
											className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm opacity-70 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
										>
											<img
												src={FirefoxLogo}
												className="w-6 h-6 mr-2"
											/>{" "}
											Add to Firefox
										</button>
									</div>
								</div>
							</div>
							<div className="mt-4">
								<Link
									to="privacypolicy"
									className="border-b-2 border-dotted"
								>
									Privacy policy
								</Link>
							</div>
						</div>
						<div className="flex items-center overflow-hidden lg:w-2/5">
							<img src={Reading} />
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default IndexPage;
